'use client'
import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_CADASTRE_REGISTRANTS } from 'graphql/mutations'
import { GetCadastreRegistrantByGlobalId } from '__generated__/GetCadastreRegistrantByGlobalId'
import { INTERCOM_APP_ID } from 'config/constants'
import { useSession } from 'next-auth/react'
import { useIntercom } from 'hooks/useIntercom'
import { usePathname } from 'next/navigation'

export default function Intercom() {
  const { data: session } = useSession()
  const { setIsOpen } = useIntercom()
  const pathname = usePathname()

  const { data: registrantsData, loading: loadingRegistrants } =
    useQuery<GetCadastreRegistrantByGlobalId>(GET_CADASTRE_REGISTRANTS, {
      variables: { customerId: session?.user.id as string },
    })

  function createIntercomMetaTag() {
    const meta = document.createElement('span')
    meta.id = 'intercom-viewport-meta'
    document.getElementById('intercom-container')?.appendChild(meta)
  }

  useEffect(
    function update() {
      if (!window.Intercom) return
      window.Intercom('update')
    },
    [pathname]
  )

  useEffect(
    function loadIntercom() {
      if (loadingRegistrants || !session?.user.id || !window.Intercom) {
        return
      }
      const firstname =
        registrantsData?.getCadastreRegistrantByGlobalId?.cadastreRegistrant
          ?.firstName
      const lastName =
        registrantsData?.getCadastreRegistrantByGlobalId?.cadastreRegistrant
          ?.lastName

      window.Intercom('boot', {
        app_id: INTERCOM_APP_ID,
        api_base: 'https://api-iam.eu.intercom.io',
        user_id: session?.user.id as string,
        name: `${firstname} ${lastName}`,
        email:
          registrantsData?.getCadastreRegistrantByGlobalId?.cadastreRegistrant
            ?.email ?? undefined,
      })
      window.Intercom('onHide', function () {
        createIntercomMetaTag()
        setIsOpen(false)
      })
      window.Intercom('onShow', function () {
        setIsOpen(true)
      })
      return () => {
        window.Intercom('shutdown')
      }
    },
    [
      loadingRegistrants,
      session?.user.id,
      registrantsData?.getCadastreRegistrantByGlobalId,
      setIsOpen,
    ]
  )

  return (
    <div id="intercom-container">
      <span id="intercom-viewport-meta" />
    </div>
  )
}

'use client'

import { useSession, signIn } from 'next-auth/react'
import { LoadingSun } from 'components/atoms/LoadingSun'
import * as React from 'react'
import { datadogRum } from '@datadog/browser-rum'

type Props = {
  children: React.ReactNode
}

export const Auth: (props: Props) => JSX.Element = ({ children }) => {
  const { status, data: session } = useSession()

  if (status === 'loading') {
    return <LoadingSun background="bg-enpalGray-50" />
  }

  datadogRum.setUser({ email: session?.user?.email ?? "", name: session?.user?.name ?? "" })
  if (status !== 'authenticated') {
    signIn(
      'azure-ad-b2c',
      { callbackUrl: `${window.location.origin}` },
      { prompt: 'login' }
    )
    return <LoadingSun background="bg-enpalGray-50" />
  }

  if (session?.error === 'RefreshAccessTokenError') {
    signIn(
      'azure-ad-b2c',
      { callbackUrl: `${window.location.origin}` },
      { prompt: 'login' }
    )
    return <LoadingSun background="bg-enpalGray-50" />
  }

  return <div data-test-id="authenticated">{children}</div>
}

export default Auth

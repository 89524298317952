import type { SetState } from 'zustand'
import { GlobalStore } from './store'

export type HelpOverlaySlice = {
  showHelpOverlay: boolean
  toggleHelpOverlay: () => void
}


export const createHelpOverlaySlice = (set: SetState<GlobalStore>) => ({
  showHelpOverlay: false,
  toggleHelpOverlay: () =>
    set((s) => ({ showHelpOverlay: !s.showHelpOverlay })),
})

export const helpOverlaySelector = (state: GlobalStore) => state.showHelpOverlay

export const toggleHelpOverlaySelector = (state: GlobalStore) =>
  state.toggleHelpOverlay

import type { SetState } from 'zustand'
import { GlobalStore } from './store'

export type HistorySlice = {
  history: Array<string>
  pushToHistory: (newPage: string) => void
  popFromHistory: () => void
  clearHistory: () => void
}

export const createHistorySlice = (set: SetState<GlobalStore>) => ({
  history: [],
  pushToHistory: (newPage: string) =>
    set((state) => ({ history: [...state.history, newPage] })),
  popFromHistory: () =>
    set((state) => ({
      history: [...state.history.slice(0, state.history.length - 1)],
    })),
  clearHistory: () => set(() => ({ history: [] })),
})

export const historySelector = (state: GlobalStore) => state.history

export const pushToHistorySelector = (state: GlobalStore) => state.pushToHistory

export const popFromHistorySelector = (state: GlobalStore) =>
  state.popFromHistory

export const isAnyPrevPageSelector = (state: GlobalStore) => () =>
  state.history.length > 1

export const clearHistorySelector = (state: GlobalStore) => state.clearHistory

export const getHistoryTopSelector = (state: GlobalStore) => () =>
  state.history[state.history.length - 1]

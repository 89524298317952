import SunIcon from 'assets/icons/sun-icon.svg'
import OulineSunIcon from 'assets/icons/sun-outline-icon.svg'
import cn from 'classnames'

interface Props {
  inPlace?: boolean
  flexGrow?: boolean
  width?: string
  height?: string
  color?: string
  outlined?: boolean
  background?: string
}

export function LoadingSun({
  inPlace = false,
  flexGrow = true,
  height = '48px',
  width = '48px',
  color = 'text-enpal-purple',
  background = 'bg-inherit',
  outlined = false,
}: Props): JSX.Element {
  return (
    <div
      className={cn(
        'inset-0',
        { 'flex-grow': flexGrow },
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
        background,
        { fixed: !inPlace }
      )}
    >
      {outlined ? (
        <OulineSunIcon
          height={height}
          width={width}
          className={cn('animate animate-spin-slow', color)}
        />
      ) : (
        <SunIcon
          height={height}
          width={width}
          className={cn('animate animate-spin-slow', color)}
        />
      )}
    </div>
  )
}

import { ApolloError } from '@apollo/client'
import { client } from 'client'
import { LOGIN, REFRESH } from 'graphql/mutations'
import { useStore } from 'store/store'
import type { Login, LoginVariables } from '__generated__/Login'
import type {
  RefreshToken,
  RefreshTokenVariables,
} from '__generated__/RefreshToken'

type Options<T> = {
  onSuccess: (data: T) => void
  onError: (err: ApolloError) => void
  onSettled?: () => void
}

type LoginOptions = Options<Login>
type RefreshOptions = Options<RefreshToken>

export const REFRESH_TOKEN_COOKIE_NAME = 'enpal_refresh_token'

export const handleRefresh = (
  token: string | null | undefined,
  { onSuccess, onError, onSettled }: RefreshOptions
) => {
  if (token) {
    client
      .mutate<RefreshToken, RefreshTokenVariables>({
        mutation: REFRESH,
        variables: { refreshToken: token },
      })
      .then(
        ({ data }) => {
          data && onSuccess(data)
        },
        (err) => {
          onError(err)
        }
      )
      .finally(() => {
        onSettled && onSettled()
      })
  } else {
    onSettled && onSettled()
  }
}

export const handleLogin = (
  code: string | null | undefined,
  { onSuccess, onError, onSettled }: LoginOptions
) => {
  if (code) {
    client
      .mutate<Login, LoginVariables>({
        mutation: LOGIN,
        variables: { loginCode: code },
      })
      .then(
        ({ data }) => {
          data && onSuccess(data)
        },
        (err) => {
          onError(err)
        }
      )
      .finally(() => {
        onSettled && onSettled()
      })
  } else {
    onSettled && onSettled()
  }
}

export const handleAuthError = (err: ApolloError) => {
  if (err.message.toLowerCase().includes('unauthorized')) {
    useStore.getState().dispatch({ type: 'QUEUE_REFRESH' })
  }
}

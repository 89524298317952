const LANGUAGE_KEYS = {
  IPO_TITLE: 'ipo.title',
  START_METER_VALUES_TILE_HEADING: 'start.meter-values.tile',
  //METER_VALUES_HEADING: 'meter-values.heading',
  DAMAGE_FORM_HEADING: 'damage-form.heading',
  DOCUMENTS_HANDBOOKS_HEADING: 'document-overview.category.Handbooks',
  DOCUMENTS_PAYMENTS_HEADING: 'document-overview.category.Payment',
  START_HEADING_GREETING: 'start.heading.greeting',
  START_HEADING_GREETING_MORNING: 'greeting.morning',
  START_HEADING_GREETING_DAY: 'greeting.day',
  START_HEADING_GREETING_EVENING: 'greeting.evening',
  START_HEADING_FAMILY: 'start.heading.family',
  START_SUBHEADING: 'start.subheading',
  START_FINANCIAL_OVERVIEW_TILE_HEADING:
    'start.financial-overview-tile.heading',
  START_LEASE_OVERVIEW_TILE_HEADING: 'start.lease-overview-tile.heading',
  START_Q_AND_A_TILE_HEADING: 'start.q-and-a-tile.heading',
  FINANCIAL_OVERVIEW_HEADING: 'financial-overview.heading',
  FINANCIAL_OVERVIEW_FINANCIAL_INFORMATION_HEADING:
    'financial-overview.financial-information.heading',
  FINANCIAL_OVERVIEW_FINANCIAL_INFORMATION_MONTHLY_RATE_TOP_LABEL:
    'financial-overview.financial-information.monthly-rate.top-label',
  FINANCIAL_OVERVIEW_FINANCIAL_INFORMATION_MONTHLY_RATE_DESCRIPTION:
    'financial-overview.financial-information.monthly-rate.description',
  FINANCIAL_OVERVIEW_LOAN_INFORMATION_MONTHLY_RATE_PENDING:
    'monthly-rate.pending',
  FINANCIAL_OVERVIEW_LOAN_INFORMATION_MONTHLY_RATE_PENDING_DESCRIPTION:
    'monthly-rate.pending.description',
  FINANCIAL_OVERVIEW_FINANCIAL_INFORMATION_OUTSTANDING_AMOUNT_TOP_LABEL:
    'financial-overview.financial-information.outstanding-amount.top-label',
  FINANCIAL_OVERVIEW_FINANCIAL_INFORMATION_OUTSTANDING_AMOUNT_DESCRIPTION:
    'financial-overview.financial-information.outstanding-amount.description',
  FINANCIAL_OVERVIEW_FINANCIAL_INFORMATION_NEW_OUTSTANDING_AMOUNT_TOP_LABEL:
    'financial-overview.down-payment.new-outstanding-amount.top-label',
  FINANCIAL_OVERVIEW_FINANCIAL_INFORMATION_NEW_MONTHLY_RATE_TOP_LABEL:
    'financial-overview.down-payment.new-monthly-rate.top-label',
  FINANCIAL_OVERVIEW_DOWN_PAYMENT_DISCLAIMER:
    'financial-overview.down-payment-disclaimer',
  FINANCIAL_OVERVIEW_FINANCIAL_INFORMATION_DURATION_TOP_LABEL:
    'financial-overview.financial-information.duration.top-label',
  FINANCIAL_OVERVIEW_FINANCIAL_INFORMATION_DURATION_DESCRIPTION:
    'financial-overview.financial-information.duration.description',
  FINANCIAL_OVERVIEW_FINANCIAL_CUSTOMER_NUMBER_TOP_LABEL:
    'financial-overview.financial-information.customer-number.top-label',
  FINANCIAL_OVERVIEW_PAYMENT_INFORMATION_HEADING:
    'financial-overview.payment-information.heading',
  FINANCIAL_OVERVIEW_PAYMENT_INFORMATION_IBAN_TOP_LABEL:
    'financial-overview.payment-information.iban.top-label',
  FINANCIAL_OVERVIEW_PAYMENT_INFORMATION_BANK_TOP_LABEL:
    'financial-overview.payment-information.bank-top-label',
  FINANCIAL_OVERVIEW_NEW_PAYMENT_METHOD_LABEL:
    'financial-overview.payment-information.no-sepa.label',
  FINANCIAL_OVERVIEW_NEW_PAYMENT_METHOD_TOP_LABEL:
    'financial-overview.payment-information.no-sepa.top-label',
  BUTTON_ADD_PAYMENT_METHOD: 'button.add-payment-method',
  FINANCIAL_OVERVIEW_DOWN_PAYMENT_HEADING:
    'financial-overview.down-payment.heading',
  FINANCIAL_OVERVIEW_DOWN_PAYMENT_SUBHEADING:
    'financial-overview.down-payment.subheading',
  FINANCIAL_OVERVIEW_DOWN_PAYMENT_INFO_POPUP_DESCRIPTION:
    'financial-overview.down-payment.info-popup.description',
  FINANCIAL_OVERVIEW_DOWN_PAYMENT_INFO_POPUP_LINk:
    'financial-overview.down-payment.info-popup.link',
  FINANCIAL_OVERVIEW_DOWN_PAYMENT_ACCOUNT_OWNER_TOP_LABEL:
    'financial-overview.down-payment.account-owner.top-label',
  FINANCIAL_OVERVIEW_DOWN_PAYMENT_IBAN_TOP_LABEL:
    'financial-overview.down-payment.iban.top-label',
  FINANCIAL_OVERVIEW_DOWN_PAYMENT_SUBJECT_TOP_LABEL:
    'financial-overview.down-payment.subject.top-label',
  FINANCIAL_OVERVIEW_DOWN_PAYMENT_SUBJECT_LABEL:
    'financial-overview.down-payment.subject.label',
  FINANCIAL_OVERVIEW_DOWN_PAYMENT_MODAL_HEADING:
    'financial-overview.down-payment.modal.heading',
  FINANCIAL_OVERVIEW_DOWN_PAYMENT_MODAL_SUBHEADING:
    'financial-overview.down-payment.modal.subheading',
  LEASE_OVERVIEW_RENT_INFORMATION_HEADING:
    'lease-overview.rent-information.heading',
  LEASE_OVERVIEW_FINANCIAL_INFORMATION_MONTHLY_RATE_DESCRIPTION:
    'lease-overview.financial-information.monthly-rate.description',
  LEASE_OVERVIEW_FINANCIAL_INFORMATION_MONTHLY_RATE_DESCRIPTION_VAT:
    'lease-overview.financial-information.monthly-rate.description-VAT',
  LEASE_OVERVIEW_FINANCIAL_INFORMATION_MONTHLY_RATE_DESCRIPTION_NO_VAT:
    'lease-overview.financial-information.monthly-rate.description-no-VAT',
  LEASE_OVERVIEW_PAYMENT_INFORMATION_SUBLINE:
    'lease-overview.payment-information.subline',
  FOOTER_COPY_RIGHT: 'footer.copyright',
  FOOTER_IMPRINT: 'footer.imprint',
  FOOTER_PRIVACY: 'footer.privacy',
  ERROR_GENERAL: 'error.general',
  ERROR_PAGE_HEADING: 'error-page.heading',
  ERROR_PAGE_DESCRIPTION: 'error-page.description',
  ERROR_PAGE_DESCRIPTION_LINK_PLACEHOLDER:
    'error-page.description-link-placeholder',
  FINANCIAL_OVERVIEW_FINANCIAL_INTORMATION_CUSTOMER_NUMBER_TOP_LABEL:
    'financial-overview.financial-information.customer-number.top-label',
  BUTTON_CANCEL: 'button.cancel',
  BUTTON_CLOSE: 'button.close',
  SPECIAL_REPAYMENT_BUTTON: 'financial-overview.down-payment.button',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_SUBLINE:
    'financial-information.payment-information.subline',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_SUBLINE_SERVICE:
    'financial-information.payment-information.subline.service',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_SUBLINE_FEED_IN:
    'financial-information.payment-information.subline.feed-in',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_INFO_POPUP_MONTHLY_RATE_HEADING:
    'financial-information.payment-information.info-popup.monthly-rate.heading',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_INFO_POPUP_COMPANY:
    'financial-information.payment-information.info-popup.company',
  ENPAL_BV: 'enpal.bv',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_INFO_POPUP_CREDITOR:
    'financial-information.payment-information.info-popup.creditor',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_INFO_POPUP_REFERENCE:
    'financial-information.payment-information.info-popup.reference',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_INFO_POPUP_SERVICE_CONTRACT_HEADING:
    'financial-information.payment-information.info-popup.service-contract.heading',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_INFO_POPUP_FEED_IN_HEADING:
    'financial-information.payment-information.info-popup.feed-in.heading',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_TAXNUMBER_TEXT:
    'financial-overview.payment-information.taxnumber-top-label',
  ENPAL_GMBH: 'enpal.gmbh',
  ENPAL_SERVICE_GMBH: 'enpal.service.gmbh',
  FINANCIAL_OVERVIEW_DOWN_PAYMENT_ACCOUNT_OWNER_LABEL:
    'financial-overview.down-payment.account-owner.label',

  BUTTON_EDIT: 'button.edit',
  BUTTON_SAVE: 'button.save',
  BUTTON_REMOVE: 'button.remove',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_EDIT_HEADING:
    'financial-overview.payment-information.edit.heading',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_EDIT_DISCLAIMER:
    'financial-overview.payment-information.edit.disclaimer',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_EDIT_SUCCESS:
    'financial-overview.payment-information.edit.success',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_TAX_TOP_LABEL:
    'financial-overview.payment-information.tax.top-label',
  FINANCIAL_INFORMATION_PAYMENT_INFORMATION_TAX_DESCRIPTION:
    'financial-overview.payment-information.tax.description',
  FINANCIAL_INFORMATION_EDIT_SUCCESS_ADDITIONAL_FEEDBACK:
    'change-bank-data.dismissable-feedback-overlay',
  FINANCIAL_INFORMATION_IBAN_INPUT_ERROR: 'iban-input.error',
  FINANCIAL_INFORMATION_PV_TAB: 'PV.heading',
  FINANCIAL_INFORMATION_HP_TAB: 'HP.heading',
  FEEDBACK_OVERLAY_GENERAL_ERROR: 'feedback-overlay.general-error',
  START_PERSONAL_INFORMATION_TILE_HEADING:
    'start.personal-information-tile.heading',
  PORTAL_TITLE: 'portal.title',
  PERSONAL_INFORMATION_PRIMARY_CONTACT_HEADING:
    'personal-information.primary-contact.heading',
  PERSONAL_INFORMATION_PRIMARY_CONTACT_SUBLINE:
    'personal-information.primary-contact.subline',
  PERSONAL_INFORMATION_NAME: 'personal-information.name',
  PERSONAL_INFORMATION_PHONE: 'personal-information.phone',
  PERSONAL_INFORMATION_EMAIL: 'personal-information.email',
  PERSONAL_INFORMATION_ADDITIONAL_PHONE_NUMBER:
    'personal-information.additional-phone-number',
  PERSONAL_INFORMATION_SECONDARY_CONTACTS_HEADING:
    'personal-information.secondary-contacts.heading',
  PERSONAL_INFORMATION_SECONDARY_CONTACTS_SUBLINE:
    'personal-information.secondary-contacts.subline',
  START_DOCUMENTS_TILE_HEADING: 'start.documents-tile.heading',
  START_DOCUMENTS_TILE_HANDBOOK_HEADING:
    'start.documents-tile.handbooks.heading',
  PERSONAL_INFORMATION_EDIT_CONTACT: 'personal-information.edit-contact',
  LABEL_OPTIONAL: 'label.optional',
  PERSONAL_INFORMATION_ADD_CONTACT_PLACEHOLDER_MOBILE:
    'personal-information.add-contact.placeholder.mobile',
  PERSONAL_INFORMATION_EDIT_CONTACT_ERROR_PHONE:
    'personal-information.edit-contact.error-phone',
  PERSONAL_INFORMATION_EDIT_CONTACT_ERROR_EMAIL:
    'personal-information.edit-contact.error-email',
  PERSONAL_INFORMATION_EDIT_CONTACT_SUCCESS:
    'personal-information.edit-contact.success',
  PERSONAL_INFORMATION_LASTNAME: 'personal-information.lastname',
  PERSONAL_INFORMATION_FIRSTNAME: 'personal-information.firstname',
  PERSONAL_INFORMATION_PASSWORD_TOP_LABEL:
    'personal-information.password.top-label',
  ADDRESS_HEADLINE: 'personal-information.address.headline',
  PERSONAL_INFORMATION_ADD_CONTACT_HEADING:
    'personal-information.add-contact.heading',
  PERSONAL_INFORMATION_ADD_CONTACT_PLACEHOLDER_EMAIL:
    'personal-information.add-contact.placeholder.email',
  PERSONAL_INFORMATION_ADD_CONTACT_PLACEHOLDER_FIRSTNAME:
    'personal-information.add-contact.placeholder.firstname',
  PERSONAL_INFORMATION_ADD_CONTACT_PLACEHOLDER_LASTNAME:
    'personal-information.add-contact.placeholder.lastname',
  PERSONAL_INFORMATION_ADD_CONTACT_MAXIMUM_WARNING:
    'personal-information.add-contact.maximum-warning',
  PERSONAL_INFORMATION_ADD_CONTACT_SUCCESS:
    'personal-information.add-contact.success',
  PERSONAL_INFORMATION_EDIT_CONTACT_EMPTY_NAME:
    'personal-information.edit-contact.empty-name',
  PERSONAL_INFORMATION_PRIMARY_CONTACT_EDIT_EMAIL_HEADING:
    'personal-information.primary-contact.edit-email.heading',
  PERSONAL_INFORMATION_PRIMARY_CONTACT_EDIT_EMAIL_DESCRIPTION:
    'personal-information.primary-contact.edit-email.description',
  PERSONAL_INFORMATION_EDIT_CONTACT_EMAIL_TAKEN:
    'personal-information.edit-contact.email-taken',
  PERSONAL_INFORMATION_DELETE_CONTACT_LABEL:
    'personal-information.delete-contact.label',
  PERSONAL_INFORMATION_DELETE_CONTACT_DESCRIPTION:
    'personal-information.delete-contact.description',
  PERSONAL_INFORMATION_DELETE_CONTACT_FEEDBACK_OVERLAY_SUCCESS:
    'personal-information.delete-contact.feedback-overlay-success',
  RESET_PASSWORD_TEXT: 'personal-information.password.edit',
  STANDARD_TAXATION: 'standard-taxation',
  SMALL_BUSINESS_REGULATION: 'small-business-regulation',
  START_REFERRAL_TITLE: 'start.referral.title',
  START_REFERRAL_DESCRIPTION: 'start.referral.description',
  START_REFERRAL_MODAL_SHARE_BUTTON: 'start.referral.modal.share-button',
  START_REFERRAL_MODAL_TOS: 'start.referral.modal.tos',
  START_REFERRAL_MODAL_THIRD_DESCRIPTION:
    'start.referral.modal.third.description',
  START_REFERRAL_MODAL_THIRD_TITLE: 'start.referral.modal.third.title',
  START_REFERRAL_MODAL_SECOND_DESCRIPTION:
    'start.referral.modal.second.description',
  START_REFERRAL_MODAL_SECOND_TITLE: 'start.referral.modal.second.title',
  START_REFERRAL_MODAL_FIRST_DESCRIPTION:
    'start.referral.modal.first.description',
  START_REFERRAL_MODAL_FIRST_TITLE: 'start.referral.modal.first.title',
  START_REFERRAL_MODAL_DESCRIPTION: 'start.referral.modal.description',
  START_REFERRAL_MODAL_TITLE: 'start.referral.modal.title',
  START_REFERRAL_COPY_TEXT: 'start.referral.copy-text',
  START_REFERRAL_COPY_SUCCESS: 'start.referral.copy.success',
  START_IPO_TILE: 'start.ipo.tile',
  IPO_INSTALLATION_HEADING: 'ipo.installation.heading',
  IPO_HEADING: 'ipo.heading',
  IPO_SUBLINE: 'ipo.subline',
  IPO_INSTALLATION_STEP_STATUS_NOT_SCHEDULED:
    'ipo.installation-step.status.not-scheduled',
  IPO_TAG_TODAY: 'ipo.tag.today',
  IPO_TAG_TOMORROW: 'ipo.tag.tomorrow',
  IPO_DEFAULT_COUNTRY: 'country.germany',
  IPO_FOLLOW_UP_TAG: 'ipo.follow-up-tag',
  IPO_INSTALLATION_STEP_COMPONENT_DELIVERY_DATE_DESCRIPTION:
    'ipo.installation-step.component-delivery.date-description',
  ENERGY_OVERVIEW_HEADING: 'enpal-energy.heading',
  IPO_TAG_CANNOT_COMPLETE: 'ipo.tag.cannot-complete',
  ENERGY_OVERVIEW_CONTRACT_INFORMATION_TOP_LABEL:
    'enpal-energy.energy-contract.heading',
  ENERGY_OVERVIEW_CONTRACT_MONTHLY_RATE: 'energy.monthly-rate.top-label',
  ENERGY_OVERVIEW_CONTRACT_MONTHLY_RATE_BASE_PRICE:
    'energy.monthly-rate.description.base-price',
  ENERGY_OVERVIEW_CONTRACT_MONTHLY_RATE_WORKING_PRICE:
    'energy.monthly-rate.description.working-price',
  ENERGY_OVERVIEW_CONTRACT_START_DATE_LABEL: 'energy.contract-start.top-label',
  ENERGY_OVERVIEW_CONTRACT_START_DATE_DESCRIPTION:
    'energy.contract-start.description',
  ENERGY_OVERVIEW_CONTRACT_CUSTOMER_NUMBER_LABEL:
    'energy.customer-number.top-label',
  IPO_PROGRESS_BAR_HEADING: 'ipo.progress-bar.heading',
  IPO_PROGRESS_BAR_LOADING_ERROR_DESCRIPTION:
    'ipo.progress-bar.loading-error.description',
  IPO_PROGRESS_BAR_DONE_DESCRIPTION: 'ipo.progress-bar.done.description',
  IPO_PROGRESS_BAR_REGISTRATION_DONE_DESCRIPTION:
    'ipo.progress-bar.registration-done.description',
  IPO_PROGRESS_BAR_CUSTOMER_ACTION_DESCRIPTION:
    'ipo.progress-bar.customer-action.description',
  IPO_PROGRESS_BAR_INSTALLATION_DONE_DESCRIPTION:
    'ipo.progress-bar.installation-done.description',
  IPO_PROGRESS_BAR_NOTHING_DONE_DESCRIPTION:
    'ipo.progress-bar.nothing-done.description',
  ENERGY_OVERVIEW_WITHDRAW_BUTTON: 'enpal-energy.withdraw.button',
  ENERGY_OVERVIEW_WITHDRAW_MODAL_HEADING: 'enpal-energy.withdraw.heading',
  ENERGY_OVERVIEW_WITHDRAW_MODAL_DESCRIPTION:
    'enpal-energy.withdraw.description',
  ENERGY_OVERVIEW_WITHDRAW_MODAL_CONFIRM_BUTTON:
    'enpal-energy.withdraw.confirm.button',
  ENERGY_OVERVIEW_WITHDRAW_SUCCESS_OVERLAY:
    'enpal-energy.withdraw.success.feedback-overlay',
  ENERGY_OVERVIEW_CANCELLATION_BUTTON: 'enpal-energy.cancellation.button',
  ENERGY_OVERVIEW_CANCELLATION_MODAL_HEADING:
    'enpal-energy.cancellation.heading',
  ENERGY_OVERVIEW_CANCELLATION_MODAL_DESCRIPTION:
    'enpal-energy.cancellation.description',
  ENERGY_OVERVIEW_CANCELLATION_MODAL_CONFIRM_BUTTON:
    'enpal-energy.cancellation.confirm.button',
  ENERGY_OVERVIEW_CANCELLATION_SUCCESS_OVERLAY:
    'enpal-energy.cancellation.success.feedback-overlay',
  ENERGY_OVERVIEW_CANCELLED_MESSAGE_ONE:
    'enpal-energy.contract-cancelled.message.one',
  ENERGY_OVERVIEW_CANCELLED_MESSAGE_TWO:
    'enpal-energy.contract-cancelled.message.two',
  IPO_SBC_TAG_WARNING: 'ipo.sbc.tag.warning',
  IPO_PROGRESS_BAR_ENERGY_FLOWING_DESCRIPTION:
    'ipo.progress-bar.energy-flowing.description',
  IPO_SBC_HEADING: 'ipo.sbc.heading',
  IPO_SBC_DESCRIPTION: 'ipo.sbc.description',
  IPO_SBC_TRASH_HEADING: 'ipo.sbc.trash.heading',
  IPO_SBC_TRASH_DESCRIPTION: 'ipo.sbc.trash.description',
  IPO_SBC_TAG_PARTNER: 'ipo.sbc.tag.partner',
  IPO_SBC_SCAFFOLDING_HEADING: 'ipo.sbc.scaffolding.heading',
  IPO_SBC_SCAFFOLDING_DESCRIPTION: 'ipo.sbc.scaffolding.description',
  IPO_SBC_CUSTOMER_WORK_HEADING: 'ipo.sbc.customer-work.heading',
  IPO_SBC_CUSTOMER_WORK_DESCRIPTION: 'ipo.sbc.customer-work.description',
  IPO_FEEDBACK_HEADING: 'ipo.feedback.heading',
  IPO_FEEDBACK_DESCRIPTION: 'ipo.feedback.description',
  IPO_FEEDBACK_BUTTON: 'ipo.feedback.button',
  DOCUMENTS_OVERVIEW_MISSING_DOCUMENTS: 'documents.document-missing',
  ERROR_PAGE_TRACE_ID_HEADING: 'error-page.trace-id',

  METER_VALUES_HEADING: 'meter-values.heading', //check in the Sarah's task
  METER_VALUES_STEP_1_HEADING: 'meter-values.step-1.heading',
  METER_VALUES_STEP_1_DESCRIPTION: 'meter-values.step-1.description',
  METER_VALUES_STEP_1_SUB_DESCRIPTION: 'meter-values.step-1.sub-description',

  METER_VALUES_STEP_2_HEADING: 'meter-values.step-2.heading',
  METER_VALUES_STEP_2_DESCRIPTION: 'meter-values.step-2.description',
  METER_VALUES_STEP_2_SUB_DESCRIPTION: 'meter-values.step-2.sub-description',

  METER_VALUES_STEP_2_A_HEADING: 'meter-values.step-2.a.heading',
  METER_VALUES_STEP_2_A_DESCRIPTION: 'meter-values.step-2.a.description',

  METER_VALUES_STEP_2_B_HEADING: 'meter-values.step-2.b.heading',
  METER_VALUES_STEP_2_B_DESCRIPTION: 'meter-values.step-2.b.description',

  METER_VALUES_STEP_2_METER_VALUE_HEADING:
    'meter-values.step-2.meter-value.heading',
  METER_VALUES_STEP_2_DATE_HEADING: 'meter-values.step-2.date.heading',

  METER_VALUES_STEP_2_METER_VALUE_ERROR:
    'meter-values.step-2.meter-value.error',

  METER_VALUES_STEP_3_HEADING: 'meter-values.step-3.heading',
  METER_VALUES_STEP_3_DESCRIPTION: 'meter-values.step-3.description',
  METER_VALUES_STEP_3_SUB_DESCRIPTION: 'meter-values.step-3.sub-description',

  METER_VALUES_STEP_3_ADD_PICTURE_LABEL:
    'meter-values.step-3.add-picture.label',
  METER_VALUES_STEP_3_ADD_PICTURE_ERROR:
    'meter-values.step-3.add-picture.error',

  METER_VALUES_STEP_3_ADD_PICTURE_ERROR_INVALID_FILE:
    'meter-values.step-3.add-picture.error.invalid-file',

  METER_VALUES_STEP_3_CHANGE_PICTURE_LABEL:
    'meter-values.step-3.change-picture.label',

  METER_VALUES_SUBMIT: 'meter-values.submit',
  METER_VALUES_SUBMIT_SUCCESS_OVERLAY: 'meter-values.submit.feedback-overlay',
  METER_VALUES_SUBMIT_ERROR_OVERLAY:
    'meter-values.submit.feedback-overlay.error',
  METER_VALUES_ALREADY_SUBMITTED_HEADING:
    'meter-values.already-submitted.heading',
  METER_VALUES_ALREADY_SUBMITTED_DESCRIPTION:
    'meter-values.already-submitted.description',
  METER_VALUES_ALREADY_SUBMITTED_DESCRIPTION_MAIN_PAGE:
    'meter-values.already-submitted.description-main-page',
  FINANCIAL_INFO_MSB_INFO_POP_UP_HEADING:
    'financial-information.payment-information.info-popup.msb.heading',
  FINANCIAL_INFO_MSB_INFO_POP_UP_CREDITOR_VALUE:
    'financial-information.payment-information.info-popup.creditor.msb-value',
}
export default LANGUAGE_KEYS

'use client'
import Auth from 'components/common/Auth'

export default function AuthenticationContext({
  children,
}: {
  children: React.ReactNode
}) {
  return <Auth>{children}</Auth>
}

export const ROUTER_PATHS = {
    MAIN: '/',
    DOCUMENTS: '/documents-overview',
    ENERGY: '/energy-overview',
    FINANCIAL: '/financial-overview',
    INSTALLATION: '/installation-overview',
    LEASE: '/lease-overview',
    PERSONAL: '/personal-information',
    METER: '/meter-values',
  }
  
  export const BACKBUTTON_ROUTES_MAP = {
    [ROUTER_PATHS.DOCUMENTS]: ROUTER_PATHS.MAIN,
    [ROUTER_PATHS.ENERGY]: ROUTER_PATHS.MAIN,
    [ROUTER_PATHS.FINANCIAL]: ROUTER_PATHS.MAIN,
    [ROUTER_PATHS.INSTALLATION]: ROUTER_PATHS.MAIN,
    [ROUTER_PATHS.LEASE]: ROUTER_PATHS.MAIN,
    [ROUTER_PATHS.PERSONAL]: ROUTER_PATHS.MAIN,
    [ROUTER_PATHS.METER]: ROUTER_PATHS.MAIN,
  }
  
'use client'
import create from 'zustand'

interface IntercomState {
  isOpen: boolean
  toggleIntercom: () => void
  setIsOpen: (isOpen: boolean) => void
}

export const useIntercom = create<IntercomState>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen: boolean) => set({ isOpen }),
  toggleIntercom: () => {
    set((state: IntercomState) => {
      if (!state.isOpen) {
        window.Intercom('show')
      } else {
        window.Intercom('hide')
      }
      return { isOpen: !state.isOpen }
    })
  },
}))

import { ApolloError } from '@apollo/client'
import { AuthActionT, reducer } from 'hooks/useAuth/store'
import create from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { Login_login } from '__generated__/Login'
import { RefreshToken_refresh } from '__generated__/RefreshToken'
import produce from 'immer'
import { createHelpOverlaySlice } from './helpOverlayStore'
import type { HelpOverlaySlice } from './helpOverlayStore'
import { HistorySlice, createHistorySlice } from './historyStore'

type ImageDeletionStatus = 'idle' | 'pending' | 'error' | 'done'

export type BasicGlobalStore = {
  loginEmail: string
  setLoginEmail: (loginEmail: string) => void
  loginMobile: string
  setLoginMobile: (loginMobile: string) => void
  user: string
  setUser: (user: string) => void
  disqualifyingReason: string
  setDisqualifyingReason: (disqualificationReason: string) => void
  taskDirection: 'next' | 'prev'
  setTaskDirection: (taskDirection: 'next' | 'prev') => void
  taskOverflowHidden: boolean
  setTaskOverflowHidden: (taskOverflowHidden: boolean) => void
  unblock: boolean
  setUnblock: (unblock: boolean) => void
  imageDeletionStatus: ImageDeletionStatus
  setImageDeletionStatus: (imageDeletionStatus: ImageDeletionStatus) => void
  savings: string | null
  setSavings: (savings: string) => void
  isGenesis: boolean
  setIsGenesis: (isGenesis: boolean) => void
  isOSWLead: boolean
  setIsOSWLead: (isOSWLead: boolean) => void
  previewImageMarginRight: number
  setPreviewImageMarginRight: (previewImageMarginRight: number) => void
  softLoginInitial: boolean
  setSoftLoginInitial: (softLoginInitial: boolean) => void
  isLoggedIn: boolean
  loginData: Login_login | null
  loginError: ApolloError | null
  loginSettled: boolean
  refreshSettled: boolean
  refreshData: RefreshToken_refresh | null
  refreshError: ApolloError | null
  /** this is true when *either* login or refresh are 'in flight' */
  loading: boolean
  token: string | null
  softLogin: boolean
  softLoginSettled: boolean
  uid: string | null
  dispatch: (action: AuthActionT) => void
  refreshQueued: boolean
  lastName: string
  setLastName: (lastName: string) => void
  welcomeModal: {
    isOpen: boolean
    close: () => void
    open: () => void
  }
  softLoginModal: {
    close: () => void
    open: (isQRCode?: boolean) => void
    isOpen: boolean
    isQRCodeVariant: boolean
    isSent: boolean
    setIsSent: (isSent: boolean) => void
    isSentTwice: boolean
    setIsSentTwice: (isSentTwice: boolean) => void
  }
  lightbox: {
    imageId: string | null
    deleteFlag: boolean
    retryFlag: boolean
    select: (imageId: string) => void
    selectRetry: (imageId: string) => void
    selectDelete: (imageId: string) => void
    cancel: () => void
    cancelRetry: () => void
    cancelDelete: () => void
  }
}

export type GlobalStore = BasicGlobalStore & HelpOverlaySlice & HistorySlice

export const useStore = create<GlobalStore>(
  devtools(
    persist(
    (set) => ({
    loginEmail: '',
    setLoginEmail: (loginEmail) => set({ loginEmail }),
    loginMobile: '',
    setLoginMobile: (loginMobile) => set({ loginMobile }),
    user: '',
    setUser: (user) => set({ user }),
    disqualifyingReason: '',
    setDisqualifyingReason: (disqualifyingReason) =>
      set({ disqualifyingReason }),
    taskDirection: 'next',
    setTaskDirection: (taskDirection) => set({ taskDirection }),
    taskOverflowHidden: true,
    setTaskOverflowHidden: (taskOverflowHidden) => set({ taskOverflowHidden }),
    unblock: false,
    setUnblock: (unblock) => set({ unblock }),
    imageDeletionStatus: 'idle',
    setImageDeletionStatus: (imageDeletionStatus) =>
      set({ imageDeletionStatus }),
    savings: null,
    setSavings: (savings) => set({ savings }),
    isGenesis: false,
    setIsGenesis: (isGenesis) => set({ isGenesis }),
    isOSWLead: false,
    setIsOSWLead: (isOSWLead: boolean) => set({ isOSWLead }),
    files: [],
    // updateFile({ id: 'one', src= 'blabla' })
    showFailedUploadWidget: false,
    previewImageMarginRight: 0,
    setPreviewImageMarginRight: (previewImageMarginRight) =>
      set({ previewImageMarginRight }),
    softLoginInitial: true,
    setSoftLoginInitial: (softLoginInitial: boolean) =>
      set({ softLoginInitial }),
    dispatch: (action) => set((state) => reducer(state, action)),
    isLoggedIn: false,
    loginData: null,
    loginError: null,
    loginSettled: false,
    refreshSettled: false,
    refreshData: null,
    refreshError: null,
    /** this is true when *either* login or refresh are 'in flight' */
    loading: false,
    token: null,
    softLogin: false,
    softLoginSettled: false,
    uid: null,
    refreshQueued: false,
    lastName: '',
    setLastName: (lastName) => set({ lastName }),
    welcomeModal: {
      isOpen: false,
      open: () =>
        set(
          produce((state) => {
            state.welcomeModal.isOpen = true
          })
        ),
      close: () =>
        set(
          produce((state) => {
            state.welcomeModal.isOpen = false
          })
        ),
    },
    softLoginModal: {
      close: () =>
        set(
          produce((state) => {
            state.softLoginModal.isOpen = false
            state.softLoginModal.isQRCodeVariant = false
          })
        ),
      open: (isQRcode) =>
        set(
          produce((state) => {
            state.softLoginModal.isQRCodeVariant = Boolean(isQRcode)
            state.softLoginModal.isOpen = true
          })
        ),
      isOpen: false,
      isQRCodeVariant: false,
      isSent: false,
      setIsSent: (isSent) =>
        set(
          produce((state) => {
            state.softLoginModal.isSent = isSent
          })
        ),
      isSentTwice: false,
      setIsSentTwice: (isSentTwice) =>
        set(
          produce((state) => {
            state.softLoginModal.isSentTwice = isSentTwice
          })
        ),
    },
    lightbox: {
      imageId: null,
      retryFlag: false,
      deleteFlag: false,
      select: (imageId: string) =>
        set(
          produce((state) => {
            state.lightbox.imageId = imageId
          })
        ),
      selectDelete: (imageId: string) =>
        set(
          produce((state) => {
            state.lightbox.imageId = imageId
            state.lightbox.deleteFlag = true
          })
        ),
      selectRetry: (imageId: string) =>
        set(
          produce((state) => {
            state.lightbox.imageId = imageId
            state.lightbox.retryFlag = true
          })
        ),
      cancelDelete: () =>
        set(
          produce((state) => {
            state.lightbox.deleteFlag = false
          })
        ),
      cancelRetry: () =>
        set(
          produce((state) => {
            state.lightbox.retryFlag = false
          })
        ),
      cancel: () =>
        set(
          produce((state) => {
            state.lightbox.imageId = null
            state.lightbox.deleteFlag = false
            state.lightbox.retryFlag = false
          })
        ),
    },
    ...createHelpOverlaySlice(set),
    ...createHistorySlice(set)
  })
   , {name: 'store'} )
)
)

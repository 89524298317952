export const TEXT_CONTENT = {
  besteuerungOptions: {
    klein: 'Kleinunternehmerregelung',
    regel: 'Regelbesteuerung',
  },
  errorMessages: {
    REQUIRED: 'Erforderliches Feld',
    INVALID_BIC: 'Ungültige BIC',
    INVALID_IBAN: 'Ungültige IBAN',
    INVALID_EMAIL: 'Ungültige E-Mail-Addresse',
    INVALID_STEUERNUMMER: 'Ungültige Steuernummer',
    INVALID_UST_ID: 'Ungültige Umsatzsteuer-Identifikationsnummer',
    GENERIC: 'Das hat nicht geklappt. Noch mal?',
  },
}

export const DE = {
  prefix: '+49 ',
  required: 'Pflichtfeld',
  errors: {
    fullName: 'Bitte Vor- und Nachname eingeben',
    address: 'Bitte Straße und Hausnummer eingeben',
    phone: {
      generic: 'Bitte Handynummer eingeben',
      tooLong: 'Ihre Handynummer muss weniger als 40 Zeichen enthalten',
      tooShort: 'Ihre Handynummer ist zu kurz',
      invalid: unescape('Bitte eine %FCberpr%FCfen Sie Ihre Handynummer'),
      region: 'Bitte geben Sie eine deutsche Handynummer ein',
    },
    mobile: {
      invalidNumber: 'Bitte überprüfen Sie Ihre Handynummer',
      networkError: 'Netzwerk-Fehler - Bitte nochmal versuchen',
    },

    email: 'Bitte E-Mail eingeben',
    zipcode: 'Bitte Postleitzahl eingeben',
  },
}

export const PRIVACY_POLICY_URL =
  'https://www.enpal.de/blog/dsgvo-informationspflichten'

export const CUSTOMER_BFF_REQUESTER_ID = 'webCustomerPortal'

export const UPLOAD_FEEDBACK_TYPEFORM_ID = 'ywo0h5C0'

export const INTERCOM_APP_ID = 'urwjjpwp'

export const ENPAL_REFINANCED_IBAN = 'DE 3110 0400 0002 7003 2611'
export const ENPAL_NON_REFINANCED_IBAN = 'DE 0410 0400 0002 7003 2612'
export const ENPAL_MONTHLY_RATE_IBAN = 'DE67ZZZ00002548020'
export const LOAN_RECORD_TYPE = process.env.LOAN_RECORD_TYPE
export const LEASE_RECORD_TYPE = process.env.LEASE_RECORD_TYPE

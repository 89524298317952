import Cookies from 'js-cookie'
import type { ApolloError } from '@apollo/client'
import type { GlobalStore } from 'store/store'
import type { Login_login } from '__generated__/Login'
import type { RefreshToken_refresh } from '__generated__/RefreshToken'
import { REFRESH_TOKEN_COOKIE_NAME } from './lib'

export type AuthActionT =
  | {
      type: 'REFRESH_INIT'
    }
  | { type: 'DISABLE_SOFT_LOGIN' }
  | { type: 'LOGIN_INIT' }
  | {
      type: 'REFRESH_SETTLED'
    }
  | {
      type: 'LOGIN_SETTLED'
    }
  | {
      type: 'SIGNOUT'
    }
  | {
      type: 'REFRESH_SUCCESS'
      payload: { data: RefreshToken_refresh }
    }
  | {
      type: 'LOGIN_SUCCESS'
      payload: { data: Login_login }
    }
  | {
      type: 'REFRESH_ERROR'
      payload: { error: ApolloError }
    }
  | {
      type: 'LOGIN_ERROR'
      payload: { error: ApolloError }
    }
  | { type: 'SET_UID'; payload: { uid: string } }
  | { type: 'SET_SAVINGS'; payload: { savings: string } }
  | { type: 'ENABLE_SOFT_LOGIN'; payload: { uid: string } }
  | { type: 'QUEUE_REFRESH' }

export const reducer = (
  state: Pick<GlobalStore, keyof GlobalStore>,
  action: AuthActionT
): Pick<GlobalStore, keyof GlobalStore> => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(action.type)
  }
  switch (action.type) {
    case 'LOGIN_INIT':
      return { ...state, loading: true }
    case 'REFRESH_INIT':
      return {
        ...state,
        loading: true,
        refreshData: null,
        refreshError: null,
        refreshSettled: false,
        refreshQueued: false,
      }
    case 'LOGIN_SUCCESS':
      saveRefreshTokenCookie(action.payload.data.refresh_token)
      sessionStorage.removeItem('enpal-onboarding-id')
      sessionStorage.removeItem('enpal-onboarding-savings')
      sessionStorage.removeItem('enpal-onboarding-email')
      return {
        ...state,
        loginData: action.payload.data,
        isLoggedIn: true,
        // we 'settle' the refresh, because we succesfully logged in and don't need to refresh
        refreshSettled: true,
        token: action.payload.data.access_token,
        softLogin: false,
        softLoginSettled: true,
      }
    case 'LOGIN_ERROR':
      deleteRefreshTokenCookie()
      return {
        ...state,
        loginError: action.payload.error,
        token: null,
        isLoggedIn: false,
        // we need to 'settle' the refresh here, because the login failed
        refreshSettled: true,
      }
    case 'LOGIN_SETTLED':
      return {
        ...state,
        loginSettled: true,
        loading: false,
      }
    case 'REFRESH_SUCCESS':
      Cookies.set(
        REFRESH_TOKEN_COOKIE_NAME,
        action.payload.data.refresh_token,
        {
          sameSite: 'strict',
          secure: true,
          expires: 30,
        }
      )
      sessionStorage.removeItem('enpal-onboarding-id')
      sessionStorage.removeItem('enpal-onboarding-savings')
      sessionStorage.removeItem('enpal-onboarding-email')
      return {
        ...state,
        refreshData: action.payload.data,
        isLoggedIn: true,
        token: action.payload.data.access_token,
        softLogin: false,
        softLoginSettled: true,
      }
    case 'REFRESH_ERROR':
      deleteRefreshTokenCookie()
      return {
        ...state,
        refreshError: action.payload.error,
        isLoggedIn: false,
        token: null,
      }
    case 'REFRESH_SETTLED':
      return {
        ...state,
        refreshSettled: true,
        loading: false,
      }
    case 'SIGNOUT':
      Cookies.remove(REFRESH_TOKEN_COOKIE_NAME)
      return {
        ...state,
        isLoggedIn: false,
        token: null,
        uid: null,
        loginData: null,
        loginError: null,
        loginSettled: false,
        refreshData: null,
        refreshError: null,
        refreshSettled: false,
      }
    case 'ENABLE_SOFT_LOGIN':
      sessionStorage.setItem('enpal-onboarding-id', action.payload.uid)
      Cookies.remove(REFRESH_TOKEN_COOKIE_NAME)
      return {
        ...state,
        softLogin: true,
        uid: action.payload.uid,
        softLoginSettled: true,
      }
    case 'DISABLE_SOFT_LOGIN':
      sessionStorage.removeItem('enpal-onboarding-id')
      sessionStorage.removeItem('enpal-onboarding-savings')
      sessionStorage.removeItem('enpal-onboarding-email')
      return { ...state, softLogin: false, softLoginSettled: true }
    case 'SET_UID':
      return { ...state, uid: action.payload.uid }
    case 'QUEUE_REFRESH':
      return { ...state, refreshQueued: true }
    default:
      if (process.env.NODE_ENV !== 'production') {
        console.error(new Error(`Unhandled action type!`))
      }
      return state
  }
}

function saveRefreshTokenCookie(value: string) {
  return Cookies.set(REFRESH_TOKEN_COOKIE_NAME, value, {
    sameSite: 'strict',
    secure: true,
    expires: 30,
  })
}

function deleteRefreshTokenCookie() {
  return Cookies.remove(REFRESH_TOKEN_COOKIE_NAME)
}
